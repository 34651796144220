import * as React from 'react';
import { graphql } from 'gatsby';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

// @ts-ignore
import * as styles from './index.module.css';

import Layout from '../components/layout';
import Hr from '../components/Hr/hr';
import ogImg from '../images/og-image.png';
import SEO from '../components/seo';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const isPl = language === 'pl';
  const email = isPl ? 'kontakt@getbarfplan.com' : 'contact@getbarfplan.com';
  const mailto = `mailto:${email}`;
  const tcUrl = isPl
    ? 'https://getbarfplan.com/pl/terms-and-conditions'
    : 'https://getbarfplan.com/terms-and-conditions';

  return (
    <Layout>
      <SEO
        title={t('Privacy policy')}
        ogUrl="https://getbarfplan.com/"
        imageUrl={ogImg}
      />

      <div className="container bg-white ph-60 md:ph-30 xs:ph-10">
        <h1>
          <Trans>Privacy policy</Trans>
        </h1>

        <p>{t('privacy-policy-page-1')}</p>
        <p>{t('privacy-policy-page-2')}</p>
        <p>{t('privacy-policy-page-3')}</p>
        <p>
          <Trans i18nKey="privacy-policy-page-4">
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which are accessible at
            <a href={tcUrl}>{{ tcUrl }}</a> unless otherwise defined in this
            Privacy Policy.
          </Trans>
        </p>

        <Hr />

        <section>
          <h2>
            <Trans>Information Collection and Use</Trans>
          </h2>

          <p>{t('privacy-policy-page-5')}</p>
          <p>{t('privacy-policy-page-6')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Log Data</Trans>
          </h2>

          <p>{t('privacy-policy-page-7')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Cookies</Trans>
          </h2>

          <p>{t('privacy-policy-page-8')}</p>
          <p>{t('privacy-policy-page-9')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Service Providers</Trans>
          </h2>

          <p>{t('privacy-policy-page-10')}</p>
          <p>{t('privacy-policy-page-11')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Security</Trans>
          </h2>

          <p>{t('privacy-policy-page-12')}</p>
          <p>{t('privacy-policy-page-13')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Changes to This Privacy Policy</Trans>
          </h2>

          <p>{t('privacy-policy-page-14')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Contact Us</Trans>
          </h2>

          <p
            dangerouslySetInnerHTML={{
              __html: t('privacy-policy-page-15', {
                emailLink: `<a href="${mailto}" target="_blank">${email}</a>`,
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </section>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
